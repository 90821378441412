<template>
  <div class="card">
    <template v-if="persona">
      <h3 class="card-title">Datos personales <small class="badge error" v-if="persona.estado === 'Inhabilitado'">Inhabilitado</small></h3>
      <div class="cols2">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="persona.nombre"
          label="Nombre"
          id="nombre"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.cuil"
          label="CUIL"
          id="cuil"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.numero_legajo"
          label="N° de legajo"
          id="numero_legajo"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.gerencia.descripcion"
          label="Gerencia"
          id="gerencia"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.area.descripcion"
          label="Área"
          id="Area"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.sucursal.descripcion"
          label="Sucursal"
          id="Sucursal"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.categoria.descripcion"
          label="Categoría"
          id="categoria"
          placeholder="categoria"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.obra_social.descripcion"
          label="Obra social"
          id="obra_social"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.email"
          label="Email"
          id="email"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.telefono_fijo"
          label="Teléfono fijo"
          id="telefono_fijo"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="celular"
          label="Celular"
          id="celular"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.domicilio"
          label="Domicilio"
          id="domicilio"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.localidad"
          label="Localidad"
          id="localidad"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="persona.provincia"
          label="Provincia"
          id="provincia"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <hr />
      <div class="d-flex space-between mt-1">
        <button class="btn btn-warning" @click="editPersona">
          Editar persona
        </button>
        <button class="btn btn-dark" @click="resetPassword">
          Reiniciar contraseña
        </button>
        <button class="btn btn-error" @click="eliminarPersona">
          Eliminar persona
        </button>
      </div>
      <!-- <div>
        <table-list
          ref="tablelist"
          :showFilter="true"
          :data="persona.licencias"
          :header="licencias_gozadas_header"
          :body="licencias_gozadas_body"
        ></table-list>
      </div> -->
    </template>
  </div>
</template>
<script>
// import TableList from "@/components/elements/TableList";
import { PersonasService } from "../services/PersonasServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarPersona from "./EditarPersona.vue";
export default {
  name: "VerPersona",
  components: {
    // TableList,
    Modal,
    EditarPersona,
  },
  data() {
    return {
      persona: null,
      openEdit: false,
      licencias_gozadas_header: [
        { value: "Licencia", sortable: true },
        { value: "Período", sortable: true },
        { value: "Fecha inicio", sortable: true },
        { value: "Fecha finalización", sortable: true },
        { value: "Días tomados", sortable: true },
        { value: "Fecha reincorporación", sortable: true },
      ],
      licencias_gozadas_body: [
        {
          class: "w-100 important",
          data: [
            { value: "tipo_licencia.descripcion", class: "text-bold" },
            {
              value: "tipo_licencia.convenio",
              class: "text-secondary text-small",
            },
          ],
        },
        {
          class: "w-100",
          data: [{ value: "periodo", label: "Período" }],
        },
        {
          class: "w-100",
          data: [{ value: "desde", label: "Desde" }],
        },
        {
          class: "w-100",
          data: [{ value: "hasta", label: "Hasta" }],
        },
        {
          class: "w-100",
          data: [{ value: "cantidad_dias", label: "Días tomados" }],
        },
        {
          class: "w-100",
          data: [
            { value: "fecha_reincorporacion", label: "Fecha reincorporación" },
          ],
        },
      ],
    };
  },
  computed: {
    celular(){
      return `${this.persona.caracteristica_celular}-${this.persona.numero_celular}`
    }
  },
  mounted() {
    PersonasService.api.find(this.$route.params.id).then((response) => {
      this.persona = response.persona;
    });
  },
  methods: {
    eliminarPersona() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.persona.nombre}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          PersonasService.api.delete(this.persona).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarPersonas" });
          });
        }
      });
    },
    editPersona() {
      this.$router.push({
        name: "EditarPersona",
        params: { id: this.persona.id },
      });
    },
    resetPassword() {
      Swal.fire({
        title: "Confirme",
        html: `<p>¿Está seguro que desea reiniciar su contraseña?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Reiniciar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          PersonasService.api.resetPassword(this.persona.id).then(() => {
            Swal.fire("Éxito", "<p>Se ha reiniciado con éxito</p>", "success");
            this.$router.push({ name: "ListarPersonas" });
          });
        }
      });
    },
  },
};
</script>
